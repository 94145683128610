(function($) {
	$doc = $(document);

  var taxObj = {};

  //parametes par défaut
  $params    = {
    'page'       : '1',
    'layout'     : $('#container-async').data('layout'),
    'qty'        : $('#container-async').data('paged'),
    'cpt'        : $('#container-async').data('cpt'),
    'keyword'    : null,
    'taxObj'     : taxObj,
  };


	$doc.ready( function() {

		/**
		 * Retrieve posts
		 */
		function get_posts($params) {

			$container = $('#container-async');
			$content   = $container.find('.content');
      if ( $container.find('.pagination').length == 0 ) {
        $content.after('<div id="pagination"></div>');
        $pagination = $container.find('#pagination');

      };
    	$status    = $container.find('.status');

			$status.text('Chargement ...');
      $('body').addClass('loading');

			$.ajax({
      		url: nextia.ajax_url,
      		data: {
      			action: 'do_filter_posts',
  					nonce: nextia.nonce,
  					params: $params
      		},
      		type: 'post',
      		dataType: 'json',
	            		success: function(data, textStatus, XMLHttpRequest) {

			            	if (data.status === 200) {
			            		$content.html(data.content);
                      $pagination.html(data.pagination);
			            	}
			            	else if (data.status === 201) {
			            		$content.html(data.content);
			            	}
			            	else {
			            		$status.html(data.message);
			            	}
			         },
			         error: function(MLHttpRequest, textStatus, errorThrown) {

					$status.html(textStatus);

					/*console.log(MLHttpRequest);
					console.log(textStatus);
					console.log(errorThrown);*/
			         },
				complete: function(data, textStatus) {

					msg = textStatus;

	            	if (textStatus === 'success') {
	            		msg = data.responseJSON.found;
	            	}

	            	// $status.text('Posts found: ' + msg);
                $status.text('');
                $('body').removeClass('loading');
	            	/*console.log(data);
	            	console.log(textStatus);*/
	            }
	        });
		}

		/**
		 * PAGINATION
		 */
    $('#container-async').on('click', '.pagination a', function(e){
        $page = parseInt($(this).attr('href').replace(/\D/g,''));
        $params.page = $page;
        get_posts($params);
    });


    $('#container-async').on('click', '[data-search]', function(e){
      e.preventDefault();
      $keyword = $('#container-async .ajax-search-input').val();
      $params.keyword = $keyword;
      get_posts($params);
    });

    $('#container-async').on('change', '.nav-filter_select', function(e){
      var current_tax = $(this).find(':selected').data('filter');
      $params.page = 1;
      $params.taxObj[current_tax] = [$(this).find(':selected').data('term')];
      get_posts($params);

    });

		$('#container-async').on('click', 'a[data-filter]', function(event) {

			if(event.preventDefault) {
        event.preventDefault();
      }

			$this = $(this);

			/**
			 * Set filter active
			 */

			if ($this.data('filter')) {

				$this.closest('ul').find('.active').removeClass('active');
				$this.parent('li').addClass('active');

  			}
        // $params.page = $page;
        var current_tax = $this.data('filter');
        $params.page = 1;
        $params.taxObj[current_tax] = [$this.data('term')];
        get_posts($params);

		});

		$('a[data-term="all-terms"]').trigger('click');

	});

})(jQuery);
