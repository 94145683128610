//MENU HAMBURGER
(function() {
  "use strict";
  var toggles = document.querySelectorAll(".header__toggle-menu");
  var body = document.querySelector("body");


  for (var i = toggles.length - 1; i >= 0; i--) {
    var toggle = toggles[i];
    toggleHandler(toggle);
  };

  function toggleHandler(toggle) {
    toggle.addEventListener( "click", function(evt) {
      evt.preventDefault();
      (this.classList.contains("is-active") === true) ? this.classList.remove("is-active") : this.classList.add("is-active");
      (body.classList.contains("with-nav") === true) ? body.classList.remove("with-nav") : body.classList.add("with-nav");
    });
  }
})();

$(window).scroll(function(evt) {
    var top = 100;
    var y = $(this).scrollTop();
    if ( y > top ) {
      $('body').addClass('with-fixed-header');
    }
    else {
      $('body').removeClass('with-fixed-header');
    }
});


//MEGAMENU
$( ".with-megamenu" )
  .mouseenter(function() {
    $(this).addClass('is-active');
    // $('.mega-menu').show();
  })
  .mouseleave(function() {
      $(this).removeClass('is-active');
      // $('.mega-menu').hide();
  });


//double tap pour aller sur le lien (premier tap fait apparaitre le sous-menu)
// $( 'li.menu-item-has-children' ).doubleTapToGo();
