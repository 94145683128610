'use strict';

;( function( $, window, document, undefined )
{
	$( '.inputfile' ).each( function()
	{
		var $input	 = $( this ),
			  $label	 = $input.parents().find('.filename');
        console.log($label);

		$input.on( 'change', function( e )
		{
      var filepath = this.value;
      var m = filepath.match(/([^\/\\]+)$/);
      var filename = m[1];
			$label.html( filename );
		});
	});
})( jQuery, window, document );
