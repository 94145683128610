 $(document).foundation();
  $(".site-main").fitVids();

if ($('.grid_masonry').length) {
$('.grid_masonry').masonry({
// set itemSelector so .grid-sizer is not used in layout
  itemSelector: '.grid__item',
  // use element for option
  columnWidth: '.grid__item',
  percentPosition: true,
  horizontalOrder: true
});
}
